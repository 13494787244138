import "core-js/modules/es6.array.find-index.js";
/**
 * @params :
 * @param {string} id    - !required
 * @param {string} title - required
 * @param {string} text  - required
 * @param {string} image - !required
 * @param {boolean} closable -!required. Default true
*/

export var useToastStore = defineStore({
  id: 'toast',
  state: function state() {
    return {
      toasts: []
    };
  },
  actions: {
    add: function add(alert) {
      if (alert.id) {
        var index = this.toasts.findIndex(function (t) {
          return t.id === alert.id;
        });
        if (index >= 0) return true;
      }
      var now = new Date();
      var def = {
        id: now.getTime(),
        date: now,
        title: '',
        text: '',
        image: null,
        closable: true,
        action: null,
        userImage: false,
        actionTitle: 'Дія',
        autoClose: 3500
      };
      this.toasts.push(Object.assign(def, alert));
    },
    remove: function remove(id) {
      var index = this.toasts.findIndex(function (t) {
        return t.id === id;
      });
      if (index >= 0) this.toasts.splice(index, 1);
    }
  }
});