import "core-js/modules/es6.string.repeat.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.typed.uint8-array.js";
// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
import { useAppStore } from '~/stores/app';
var urlB64ToUint8Array = function urlB64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  var rawData = atob(base64);
  var outputArray = new Uint8Array(rawData.length);
  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
export default function (context, inject) {
  var appStore = useAppStore(context.$pinia);
  var deleteSubscription = function deleteSubscription(subscription) {
    return fetch('/delete-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify(subscription)
    }).then(function (response) {
      if (!response.ok) throw new Error('Bad status code from server.');
      return response;
    });
  };
  function unsubscribe() {
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      // To unsubscribe from push messaging, you need get the
      // subcription object, which you can call unsubscribe() on.
      serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {
        if (!subscription) {
          appStore.setState({
            key: 'isPushEnabled',
            value: false
          });
          return;
        }
        subscription.unsubscribe().then(function () {
          appStore.setState({
            key: 'isPushEnabled',
            value: false
          });
          return deleteSubscription(subscription);
        }).catch(function (e) {
          window.Demo.debug.log('Unsubscription error: ', e);
        });
      }).catch(function (e) {
        window.Demo.debug.log('Error thrown while unsubscribing from ' + 'push messaging.', e);
      });
    });
  }
  var saveSubscription = function saveSubscription(subscription) {
    return fetch('/save-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify(subscription)
    }).then(function (response) {
      if (!response.ok) throw new Error('Bad status code from server.');
      return response;
    });
  };
  function subscribe() {
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      var applicationServerKey = urlB64ToUint8Array('BIzAu6IygdEIBKTZLl4OEHEP5L4cfOo0-xAugdOZis8Ub785vVeLdpAL02cU4qvTZ2DDj4SuPtiuP0xBzKEDYdU');
      var options = {
        applicationServerKey: applicationServerKey,
        userVisibleOnly: true
      };
      serviceWorkerRegistration.pushManager.subscribe(options).then(function (subscription) {
        return saveSubscription(subscription);
      }).catch(function (e) {
        appStore.setState({
          key: 'isPushEnabled',
          value: false
        });
      });
    });
  }

  // Once the service worker is registered set the initial state
  function initialiseState() {
    // Are Notifications supported in the service worker?
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      return;
    }
    if (!('pushManager' in ServiceWorkerRegistration.prototype)) {
      return;
    }
    if (Notification.permission === 'denied') return;

    // We need the service worker registration to check for a subscription
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      if (Notification.permission !== 'granted') return;

      // Do we already have a push message subscription?
      serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {
        // We are good to create a new subscription
        if (!subscription) return subscribe();

        // Keep server in sync with the latest subscription
        // In case of user changing
        saveSubscription(subscription);
        appStore.setState({
          key: 'isPushEnabled',
          value: true
        });
      }).catch(function (err) {
        console.log(err);
      });
    });
  }

  // Register
  function register() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/notification-sw.js').then(initialiseState);
    }
  }

  // Register Service worker after main thread is done
  // missed the load event, run now
  if (document.readyState === "complete") {
    setTimeout(function () {
      return register();
    }, 200);
  } else {
    window.addEventListener("load", function () {
      setTimeout(function () {
        return register();
      }, 200);
    });
  }

  // Make $pushSubscribe global
  inject('pushSubscribe', subscribe);
  context.$pushSubscribe = subscribe;

  // Make $pushUnsubscribe global
  inject('pushUnsubscribe', unsubscribe);
  context.$pushUnsubscribe = unsubscribe;
}