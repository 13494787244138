import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.string.link.js";
import { useCookieStore } from '~/stores/cookie';
export default function (context, inject) {
  var head = context.app.head || {};
  var cookieStore = useCookieStore();

  // Check if the link already exists to avoid duplicates
  function preloadLink(link) {
    if (!head.link.find(function (item) {
      return item.href === link.href;
    })) {
      head.link = [].concat(_toConsumableArray(head.link), [link]);
    }
  }
  var globalGtag = function globalGtag(type, name, data) {
    console.log('Debug GA:');
    console.log(type, name, data);
  };

  // Only run in production mode
  if (process.env.NODE_ENV !== 'production') {
    inject('gtag', globalGtag);
    context.$gtag = globalGtag;
    return;
  }

  // Dns-prefetch
  preloadLink({
    hid: 'gtm-dns-prefetch',
    rel: "dns-prefetch",
    href: "https://www.googletagmanager.com/"
  });

  // Preload Gtag script
  preloadLink({
    href: "https://www.googletagmanager.com/gtag/js?id=G-Y08LM37YY5",
    rel: "preload",
    as: "script"
  });

  // Dont load sctipts on server side
  if (!process.client) return;
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  // Default consent mode 
  gtag('consent', 'default', {
    'ad_storage': cookieStore.getCookieConsent.marketing ? 'granted' : 'denied',
    'ad_user_data': cookieStore.getCookieConsent.marketing ? 'granted' : 'denied',
    'ad_personalization': cookieStore.getCookieConsent.marketing ? 'granted' : 'denied',
    'analytics_storage': cookieStore.getCookieConsent.performance ? 'granted' : 'denied',
    'functionality_storage': cookieStore.getCookieConsent.consent ? 'granted' : 'denied',
    'security_storage': cookieStore.getCookieConsent.consent ? 'granted' : 'denied',
    'personalization_storage': cookieStore.getCookieConsent.performance ? 'granted' : 'denied',
    'wait_for_update': 500
  });

  // Ingect Gtag script
  (function (d, s) {
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s);
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y08LM37YY5';
    f.parentNode.insertBefore(j, f);
  })(document, 'script');
  gtag('js', new Date());

  // СASES GTM
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-5L2MTGD');

  /**
   * CASES GA4
   * @todo: Add debug mode
   * @see: https://support.google.com/analytics/answer/7201382#zippy=%2C%D1%82%D0%B5%D0%B3-google-gtagjs
   */
  if (context.$auth.$state.user) {
    gtag('config', 'G-Y08LM37YY5', {
      'user_id': context.$auth.$state.user.id
    });
  } else {
    gtag('config', 'G-Y08LM37YY5');
  }

  // Track new registration if query parameter exists
  if (context.route.query.ctm_nr) {
    gtag("event", "sign_up");
  }
  globalGtag = function globalGtag(type, name, data) {
    gtag(type, name, data);
  };

  // Make gtag globally accessible
  inject('gtag', globalGtag);
  context.$gtag = globalGtag;
}