var casesStructuredClone = function casesStructuredClone(obj) {
  if (typeof structuredClone !== 'undefined') {
    return structuredClone(obj);
  } else {
    return JSON.parse(JSON.stringify(obj));
  }
};
export default (function (context, inject) {
  inject('structuredClone', casesStructuredClone);
  context.$structuredClone = casesStructuredClone;
});