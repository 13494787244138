import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
export var useAppStore = defineStore({
  id: "app",
  state: function state() {
    return {
      mainMenuOpen: false,
      gainsAllowed: true,
      scroll: 0,
      version: false,
      os: null,
      csrfToken: null,
      hlsLoaded: false,
      qriousLoaded: false,
      qriousLoading: false,
      SFFontLoaded: false,
      SFFontLoading: false,
      iframelyLoaded: false,
      editorLoaded: false,
      editorLoading: false,
      langSwitching: false,
      votedReaction: null,
      isPushEnabled: false,
      activeProfile: {},
      colorMode: "light",
      lastPage: null
    };
  },
  actions: {
    setState: function setState(_ref) {
      var key = _ref.key,
        value = _ref.value;
      this[key] = value;
    },
    loadSFFonts: function loadSFFonts() {
      var _this = this;
      if (this.SFFontLoaded || this.SFFontLoading || typeof document == 'undefined') return;
      this.SFFontLoading = true;

      // Define new font SF
      var SFFontFace = new FontFace('SF UI Display', 'url(/fonts/SFUIDisplay-Regular.woff)');
      document.fonts.add(SFFontFace);

      // Define Bold FS
      var SFBoldFontFace = new FontFace('SF UI Display', 'url(/fonts/SFUIDisplay-Bold.woff)');
      SFBoldFontFace.weight = 'bold';
      document.fonts.add(SFBoldFontFace);

      // Define Heavy FS
      // Note: This font is used for canvas fonts. But canvas supports 'normal' and 'bold' font-weight ony
      //       thats why it made as separated font 'SF UI Display Heavy'
      var SFHeavyFontFace = new FontFace('SF UI Display Heavy', 'url(/fonts/SFUIDisplay-Heavy.woff)');
      document.fonts.add(SFHeavyFontFace);

      // Force load fonts
      // By default it loads only by browser demand
      // so it can be too late for canvas rendering
      SFFontFace.load();
      SFBoldFontFace.load();
      SFHeavyFontFace.load();

      // Start rendering canvas after fonts are ready
      Promise.all([SFFontFace.loaded, SFBoldFontFace.loaded, SFHeavyFontFace.loaded]).then(function () {
        _this.SFFontLoaded = true;
      });
    }
  }
});