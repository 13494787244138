import { useSubscriptionStore } from '~/stores/subscription';
import { useReportCardStore }   from '~/stores/reportCard';
import { useCookieStore }       from '~/stores/cookie';

export default function ({ $pinia, isHMR, req, res }) {
    const subscriptionStore = useSubscriptionStore($pinia);
    const reportCardStore   = useReportCardStore($pinia);
    const cookieStore       = useCookieStore($pinia);


    if (!subscriptionStore.hydrated) subscriptionStore.hydrate();
    if (!reportCardStore.hydrated)   reportCardStore.hydrate();


    // Run for server only
    if (!isHMR && req && process.server && req.headers.cookie) {
        if (!cookieStore.hydrated) cookieStore.hydrate(req, res);
    }
}
