import { useProfileStore }   from '~/stores/profile';
import { useMenuPanelStore } from '~/stores/menuPanel';
import { useModalityStore }  from '~/stores/modality';

export default function ({store, route, from, $pinia}) {
    if (from && route.path !== from.path) {
        const profileStore   = useProfileStore($pinia);
        const menuPanelStore = useMenuPanelStore($pinia);
        const modalityStore  = useModalityStore($pinia);

        modalityStore.closeAllModals();
        profileStore.close();
        menuPanelStore.hideAll();
    }
}
