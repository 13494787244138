export var useTimeStore = defineStore({
  id: 'time',
  state: function state() {
    return {
      now: new Date()
    };
  },
  actions: {
    updateTime: function updateTime() {
      this.now = new Date();
    },
    start: function start() {
      var _this = this;
      setInterval(function () {
        _this.updateTime();
      }, 1000 * 60);
    }
  }
});