const middleware = {}

middleware['closeModals'] = require('../client/middleware/closeModals.js')
middleware['closeModals'] = middleware['closeModals'].default || middleware['closeModals']

middleware['gains'] = require('../client/middleware/gains.js')
middleware['gains'] = middleware['gains'].default || middleware['gains']

middleware['hydrate'] = require('../client/middleware/hydrate.js')
middleware['hydrate'] = middleware['hydrate'].default || middleware['hydrate']

middleware['i18n'] = require('../client/middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

export default middleware
