import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.find-index.js";
export var useMenuPanelStore = defineStore({
  id: 'menuPanel',
  state: function state() {
    return {
      panels: [],
      opening: false
    };
  },
  getters: {
    getPanel: function getPanel(state) {
      return function (id) {
        if (typeof id != 'string') return false;
        var panel = state.panels.find(function (item) {
          return item.id === id;
        });
        return panel && Object.assign({}, panel);
      };
    },
    getActive: function getActive() {
      var _this = this;
      return function (id) {
        if (typeof id != 'string') return false;
        var panel = _this.getPanel(id);
        return panel && panel.active;
      };
    },
    getClosing: function getClosing() {
      var _this2 = this;
      return function (id) {
        if (typeof id != 'string') return false;
        var panel = _this2.getPanel(id);
        return panel && panel.closing;
      };
    },
    getHidden: function getHidden() {
      var _this3 = this;
      return function (id) {
        if (typeof id != 'string') return false;
        var panel = _this3.getPanel(id);
        return panel && panel.hidden;
      };
    },
    isOpen: function isOpen(state) {
      return state.panels.some(function (o) {
        return !o.hidden;
      });
    }
  },
  actions: {
    setPanels: function setPanels(panels) {
      this.panels = panels;
    },
    add: function add(panel) {
      var index = this.panels.findIndex(function (item) {
        return item.id === panel.id;
      });
      if (index < 0) {
        this.panels.push(panel);
      }
    },
    remove: function remove(panel) {
      var index = this.panels.findIndex(function (item) {
        return item.id === panel.id;
      });
      if (index >= 0) {
        this.panels.splice(index, 1);
      }
    },
    update: function update(panel) {
      var index = this.panels.findIndex(function (item) {
        return item.id === panel.id;
      });
      if (index >= 0) {
        this.panels[index] = panel;
      } else {
        this.panels.push(panel);
      }
    },
    setActive: function setActive(id, item) {
      var index = this.panels.findIndex(function (item) {
        return item.id === id;
      });
      if (index >= 0) {
        this.panels[index].active = item;
      }
    },
    setHidden: function setHidden(id, value) {
      var index = this.panels.findIndex(function (item) {
        return item.id === id;
      });
      if (index >= 0) {
        this.panels[index].hidden = !!value;
      }
    },
    setClosing: function setClosing(id, value) {
      var index = this.panels.findIndex(function (item) {
        return item.id === id;
      });
      if (index >= 0) {
        this.panels[index].closing = !!value;
      }
    },
    setOpening: function setOpening(value) {
      this.opening = !!value;
    },
    show: function show(id, item) {
      var _this4 = this;
      this.setOpening(true);

      // Hover panel
      if (!item) {
        this.setClosing(id, false);
        this.setHidden(id, false);
        return;
      }

      // Hover trigger button
      // Hover another trigger button
      if (this.isOpen) {
        this.setClosing(id, false);
        this.setHidden(id, false);
        this.setActive(id, item);

        // Hover trigger button for a first time, after it was closed
      } else {
        setTimeout(function () {
          if (_this4.opening) {
            _this4.setClosing(id, false);
            _this4.setHidden(id, false);
            _this4.setActive(id, item);
          }
        }, 300);
      }
    },
    hide: function hide(id) {
      var _this5 = this;
      this.setClosing(id, true);
      this.setOpening(false);
      setTimeout(function () {
        if (_this5.getClosing(id)) _this5.hideImmediate(id);
      }, 300);
    },
    hideImmediate: function hideImmediate(id) {
      this.setHidden(id, true);
    },
    toggle: function toggle(id, item) {
      if (this.getHidden(id)) this.show(id, item);else this.hideImmediate(id);
    },
    toggleDelay: function toggleDelay(id, item) {
      if (this.getHidden(id)) this.show(id, item);else this.hide(id);
    },
    hideAll: function hideAll() {
      var _this6 = this;
      this.panels.forEach(function (item) {
        _this6.hideImmediate(item.id);
      });
    }
  }
});