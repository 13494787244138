import Vue from 'vue';

const cropContent = function () {
    let root = document.documentElement;
    let pageYOffset = window.pageYOffset;
        // body = document.body;

    root.style.setProperty('--scroll-y', pageYOffset + 'px');

    // let dh = Math.max(
    //     body.scrollHeight, body.offsetHeight,
    //     root.clientHeight, root.scrollHeight, root.offsetHeight
    // );

    let dh = root.scrollHeight;

    let bottomPading = dh - (pageYOffset + window.innerHeight);
    bottomPading = bottomPading < 0 ? 0 : bottomPading;

    root.style.setProperty('--bottom-padding', bottomPading + 'px');
}


Vue.prototype.$cropContent = cropContent;
